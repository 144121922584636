import { render, staticRenderFns } from "./DateRangeExt.vue?vue&type=template&id=673d8824&scoped=true&"
import script from "./DateRangeExt.vue?vue&type=script&lang=js&"
export * from "./DateRangeExt.vue?vue&type=script&lang=js&"
import style0 from "./DateRangeExt.vue?vue&type=style&index=0&id=673d8824&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673d8824",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('673d8824')) {
      api.createRecord('673d8824', component.options)
    } else {
      api.reload('673d8824', component.options)
    }
    module.hot.accept("./DateRangeExt.vue?vue&type=template&id=673d8824&scoped=true&", function () {
      api.rerender('673d8824', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "tmp/tmp-8Y1lrAB0E8bX5/JS/comp/DateRangeExt.vue"
export default component.exports